
import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";


function Testimonial(){
  const { t, i18n } = useTranslation();


    const testimonials = [
      {
        id: 1,
        name: "Marie D.",
        quote:
          "Une collaboration fantastique pour une application web performante",
        image: "https://spacema-dev.com/elevate/assets/images/portfolio-1.png",
      },
      {
        id: 2,
        name: "FARAM Smith",
        quote:
          "Je recommande vivement les services de Lynos. L'assistance après livraison est de premier ordre !",
        image: "https://spacema-dev.com/elevate/assets/images/portfolio-1.png",
      },
      {
        id: 3,
        name: "Jeffrey H",
        quote:
          "Une expérience client irréprochable pour un web réussie",
        image: "https://spacema-dev.com/elevate/assets/images/portfolio-1.png",
      },
      // Add more testimonials here
    ];
  const [currentTestimonial, setCurrentTestimonial] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const newIndex = (currentTestimonial + 1) % testimonials.length;
      setCurrentTestimonial(newIndex);
    }, 5000); // Change the number to adjust transition time in milliseconds

    return () => clearInterval(intervalId);
  }, [currentTestimonial, testimonials.length]);

  const handleNextClick = () => {
    const newIndex = (currentTestimonial + 1) % testimonials.length;
    setCurrentTestimonial(newIndex);
  };

  const handlePrevClick = () => {
    const newIndex =
      currentTestimonial === 0
        ? testimonials.length - 1
        : currentTestimonial - 1;
    setCurrentTestimonial(newIndex);
  };




return (
  <div className="testimonial-carousel p-8">
    <div class="relative group px-32">
     
      <div class="cursor-pointer">
        <div class="relative p-6 space-y-6 leading-none text-gray-100 rounded-lg bg-blue-700 w-max-xl mx-auto ring-1 ring-gray-900/5">
          <div class="flex items-center space-x-4">
            <img
              src={testimonials[currentTestimonial].image}
              class="w-12 h-12 bg-center bg-cover border rounded-full"
              alt={testimonials[currentTestimonial].name}
            />
            <div>
              <h3 class="text-lg font-semibold text-white">
                {testimonials[currentTestimonial].name}
              </h3>
              <p class="text-gray-100 text-md">
                Note:  {testimonials[currentTestimonial].quote}
              </p>
            </div>
          </div>
          {/* <p class="leading-normal text-gray-300 text-md">Find God.</p> */}
          <button className="testimonial-button prev" onClick={handlePrevClick}>
            &#8592;
          </button>
          <button className="testimonial-button next" onClick={handleNextClick}>
            &#8594;
          </button>
        </div>
      </div>
    </div>

    <div className="testimonial-container"></div>
  </div>
);


}
export default Testimonial;