import WhatsAppWidget from './WhatsAppWidget';
import React from 'react';


function Footer(){

return (
  <div class="flex flex-col">
    <main class="wa flex-grow">
      <WhatsAppWidget />
    </main>
    <footer class="bg-gray-800 text-white py-4">
      <div class="container mx-auto text-center">
        <p>&copy; 2024 Sign by Lynos HK. All rights reserved.</p>
      </div>
    </footer>
  </div>
);


}
export default Footer;