import Header from "./Header";
import Footer from "./Footer";
import { useState } from "react";
import Testimonial from "./Testimonial";
import Confirmation from "./Confirmation";
import { useTranslation } from "react-i18next";

function Accueil() {
  const [showModal, setShowModal] = useState(false);
  const [succesmsg, setSuccesmsg] = useState("");
  const [errormsg, setErrormsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [error, setError] = useState(null);
  const [showConfirm, setShowConfirm] = useState();
  const { t, i18n } = useTranslation();

  function CodeConfirm() {
    setShowModal(false);

    setTimeout(() => {
      //  setShowConfirm(true);
      setShowConfirm(<Confirmation />);
      setLoading(false);
    }, 1000);
  }

  //Validation Email
  var mailformat =
    /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
  // /\S+@\S+\.\S+/
  function isValidEmail(email) {
    return mailformat.test(email);
  }

  // Function onclick loading button

  // const handleClick = () => {
  //   setLoading(true);
  //   // Perform operation and then set loading to false
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 2000);
  // };
  function getEmail() {
    // handleClick();
    setLoading(true);
    var CheckuserEmail = `${userEmail}`;

    if (CheckuserEmail === "") {
      setTimeout(() => {
        setError("Veuillez saisir votre E-mail !");
        setLoading(false);
      }, 2000);
    } else if (CheckuserEmail !== "" && !isValidEmail(CheckuserEmail)) {
      setTimeout(() => {
        setError("Votre E-mail est invalide ❌⛔️ !");
        setLoading(false);
      }, 2000);
    }
    //setError(null);
    else if (CheckuserEmail !== "") {
      const xcode = Math.random().toString(36).substring(2, 7);
      //  console.log(xcode);
      // code fragment E-mail Sender Emailjs
      if (xcode) {
        let encodedString = btoa(xcode);
        //  console.log(encodedString);f // Output: SGVsbG8gV29ybGQh

        localStorage.setItem("xtrem", encodedString);
      }

      var data = {
        service_id: process.env.React_App_service_id,
        template_id: process.env.React_App_template_id_1,
        user_id: process.env.React_App_user_id,
        template_params: {
          email: CheckuserEmail,
          code: xcode,
        },
      };

      //console.log(CheckuserEmail);
      try {
        fetch("https://api.emailjs.com/api/v1.0/email/send", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(data),
        }).then((result) => {
          // console.log(result);
          CodeConfirm();
        });
      } catch (e) {
        // console.log(e);
      }
      // code fragment
    } else if (CheckuserEmail === "") {
      setTimeout(() => {
        setLoading(false);
        setError("Veuillez saisir votre E-mail ⚠️!");
      }, 2000);
    }
  }

  // Initialisation de l'état avec un tableau de données
  const [items, setItems] = useState([
    {
      id: 1,
      name: "Plateforme d'envoie de SMS",
      category: "A",
      url: "https://spacema-dev.com/elevate/assets/images/portfolio-1.png",
      redirect: "https://5sender.com/",
    },
    {
      id: 2,
      name: "Plateforme de WhatsApp Booking",
      category: "B",
      url: "https://spacema-dev.com/elevate/assets/images/portfolio-2.png",
      redirect: "https://wasenderpro.5sender.com/",
    },
    {
      id: 3,
      name: "Plateforme de vente de compte Netflix",
      category: "A",
      url: "https://spacema-dev.com/elevate/assets/images/portfolio-3.png",
      redirect: "https://goflix.pro/",
    },
    {
      id: 4,
      name: "E-commerce",
      category: "C",
      url: "https://spacema-dev.com/elevate/assets/images/portfolio-4.png",
      redirect: "https://services.goflix.pro/",
    },
    {
      id: 5,
      name: "Site vitrine STIC CONGO",
      category: "C",
      url: "https://spacema-dev.com/elevate/assets/images/portfolio-5.png",
      redirect: "https://stic-congo.com/",
    },
    {
      id: 6,
      name: "Site vitrine FISA BENIN",
      category: "C",
      url: "https://spacema-dev.com/elevate/assets/images/portfolio-6.png",
      redirect: "https://fisa-benin.5sender.com/",
    },
  ]);

  // Fonction pour filtrer les éléments par catégorie
  const filterItems = (url) => {
    const filteredItems = items.filter((item) => item.category === url);
    setItems(filteredItems);
  };

  //Onsubmit

  const ContactSubmit = async (event) => {
    setLoading(true);
    // const ref = "cinef-" + (Math.random() + 1).toString(36).substring(7);
    //Prevent page reload
    event.preventDefault();
    var { firstname, lastname, email, phone, country, objet, msg } =
      document.forms[0];

    if (
      firstname.value === "" ||
      lastname.value === "" ||
      email.value === "" ||
      phone.value === "" ||
      objet.value === "" ||
      msg.value === "" ||
      country.value === ""
    ) {
      //console.log(email.value);
      setErrormsg("Ouuf, veuillez remplir tous les champs et rééssayer ❌ !");
      setLoading(false);      
      setTimeout(() => {        
        setErrormsg("");
      }, 1000);
      return;
    } else if (email.value !== "") {
      if (!isValidEmail(email.value)) {        
        setErrormsg("Votre Email est invalide ⚠️!");
        setLoading(false);
        setTimeout(()=>{
          setErrormsg("");
        },2000)
        return;
      }
    }

    {
      /*   const contactData = {
        firstname: firstname.value,
        lastname: lastname.value,
        email: email.value,
        phone: phone.value,
        objet: objet.value,
        msg: msg.value,
      };
*/
    }
    // Send Contact Email

    var ContactData = {
      service_id: process.env.React_App_service_id,
      template_id: process.env.React_App_template_id_2,
      user_id: process.env.React_App_user_id,
      template_params: {
        firstname: firstname.value,
        lastname: lastname.value,
        email: email.value,
        phone: phone.value,
        country: country.value,
        objet: objet.value,
        msg: msg.value,
      },
    };
      // console.log(ContactData);


    fetch("https://api.emailjs.com/api/v1.0/email/send", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(ContactData),
    }).then((result) => {
      // Send Contact Email
      // console.log(result, ContactData);

      if (result.status === 200) {
       
        setSuccesmsg("Votre message a été envoyé avec succès ✔️ !");
        setTimeout(() => {
              setLoading(false);
         setUserEmail("");
          //  setErrormsg("");
        }, 500);
      }

      if (result.status !== 200) {
        setErrormsg("Erreur de connexion, veuillez réessayer svp ⚠️ ");
         setTimeout(() => {
          setLoading(false);
           setErrormsg("");
         }, 500);
      }
    });
  };

  //Onsubmit

  return (
    <>
      <div />
      <Header />
      {showConfirm}
      {/* <Confirmation /> */}
      <body class="font-poppins dark:bg-black px-4  dark:text-gray-100  bg-gray-200">
        {/* <body class="font-poppins dark:bg-black relative  dark:text-gray-100 bg-[url('file:///F:/lynosdigiWeb/portofolio/public/Bg.jpg')] "> */}

        <div
          id="container"
          class="title-lang p-2 sm:p-16 md:p-20 lg:p-24 xl:p-20 w-auto flex flex-col md:flex-row px-4 sm:px-8 md:px-24 lg:px-24 xl:px-24 "
        >
          <div class="title-lang mr-10 w-1/2  ">
            <img
              class="min-w-[100px]  w-full rounded-full border-8 border-green-600 hover:animate-pulse zoom-in-75 md:zoom-in-50 h-auto mx-auto md:w-auto md:h-128"
              src=".././DSC_0405.JPG"
              alt="Lynos"
            />

            <h4 class=" text-3xl md:text-2xl dark:text-white font-bold mb-6">
              {t("Langues parlées et écrites")}
            </h4>
            <div class="flex mb-2 w-1/2 items-center justify-between">
              <div>
                <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-teal-600 bg-teal-200">
                  {t("Français")}
                </span>
              </div>
              <div class="title-lang text-right ">
                <span class="title-lang text-xs font-semibold inline-block text-teal-600">
                  100%
                </span>
              </div>
            </div>
            <div class="title-lang flex dark:bg-green-500 rounded-full h-2 bg-green-500 w-1/2">
              <div class="title-lang rounded-full w-full  bg-teal-500 w-1/2"></div>
            </div>
            <div class="flex mt-4 mb-2 w-1/2 items-center justify-between">
              <div>
                <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-teal-600 bg-teal-200">
                  {t("English")}
                </span>
              </div>
              <div class="title-lang text-right ">
                <span class="title-lang text-xs font-semibold inline-block text-teal-600">
                  40%
                </span>
              </div>
            </div>
            <div class="title-lang-1 flex rounded-full h-2 bg-gray-800 w-1/2">
              <div class="rang rounded-full bg-red-500 w-32"></div>
            </div>
          </div>

          <div class="but w-full sm:w-[70%] md:w-[60%] lg:w-[50%]">
            <h1 class="text-blue-500 font-bold font-sans-serif  text-3xl mt-6 mb-8 dark:text-gray-100">
              <span class="text-green-500"> {t("Hi")},</span> {t("je suis")}{" "}
              <span class="text-blue-500 font-culpa">
                {t("Lynos K. HOUNSIHOUE")}
              </span>
            </h1>

            <p
              id="apropos"
              class="text-gray-800 w-full text-justify mb-10 dark:text-gray-100"
            >
              {t(
                "Développeur web Full Stack & Marketeur passionné des solutions digitales. Mon parcours technologique a commencé avec HTML, CSS et JavaScript, et j'ai été séduit par le plaisir de créer des sites/applications web dynamiques et interactifs. Au fur et à mesure que je grandissais en compétence, Node.js et ReactJS sont devenus mes outils de prédilection pour construire des applications évolutives. N'hésitez pas à me contacter si vous avez des questions, des idées de collaboration ou si vous souhaitez simplement discuter des dernières nouveautés en matière de développement web."
              )}
            </p>

            <div
              id="social"
              class="flex flex-wrap justify-start items-center gap-4"
            >
              <a
                rel="noopener"
                target="_blank"
                href="https://github.com/lynoshk"
                class="bg-gray-800 rounded-lg p-5 w-64 flex items-center gap-2 text-white"
              >
                <img
                  class="mr-2 hover:scale-105 transition duration-300 ease-in-out"
                  src="https://ucarecdn.com/1f465c47-4849-4935-91f4-29135d8607af/github2.svg"
                  width="20px"
                  height="20px"
                  alt="Github"
                />
                <span>{t("Visit my Github")}</span>
              </a>
              <a
                rel="noopener"
                target="_blank"
                href="https://www.linkedin.com/in/lynos-komlanvi-hounsihoue-developpeur-web/"
                class="bg-gray-800 rounded-lg p-5 w-64 flex items-center gap-2 text-white"
              >
                <img
                  class="mr-2 hover:scale-105 transition duration-300 ease-in-out"
                  src="https://ucarecdn.com/95eebb9c-85cf-4d12-942f-3c40d7044dc6/linkedin.svg"
                  width="20px"
                  height="20px"
                  alt="LinkedIn"
                />
                <span>{t("Follow me on Linkedin")} </span>
              </a>
              <a
                rel="noopener"
                target="_blank"
                href="https://twitter.com/lynoshk8"
                class="bg-gray-800 rounded-lg p-5 w-64 flex items-center gap-2 text-white"
              >
                <img
                  class="mr-2 hover:scale-105 transition duration-300 ease-in-out"
                  src="https://ucarecdn.com/82d7ca0a-c380-44c4-ba24-658723e2ab07/"
                  width="20px"
                  height="20px"
                  alt="Twitter"
                />
                <span>{t("Follow me on Twitter")} </span>
              </a>
              <button
                rel="noopener"
                target="_blank"
                onClick={() => setShowModal(true)}
                // href=".././CV Lynos Certif.pdf"
                class="bg-gray-800 rounded-lg p-5 w-64 flex items-center gap-2 text-white"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="h-6 w-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m9 12.75 3 3m0 0 3-3m-3 3v-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
                <span>{t("Télécharger mon Cv")} </span>
              </button>
            </div>
            <div class="px-6 py-1 space-x-2 mx-auto text-center mt-4 space-y-4">
              <span class="inline-block px-2 py-1 font-semibold text-red-900 bg-red-200 rounded-full">
                JavaScript
              </span>
              <span class="inline-block px-2 py-1 font-semibold text-teal-900 bg-teal-200 rounded-full">
                React js
              </span>
              <span class="inline-block px-2 py-1 font-semibold text-indigo-900 bg-indigo-200 rounded-full">
                React native
              </span>
              <span class="inline-block px-2 py-1 font-semibold text-gray-900 bg-gray-200 rounded-full">
                Nest js
              </span>
              <span class="inline-block px-2 py-1 font-semibold text-purple-900 bg-purple-200 rounded-full">
                MySQL
              </span>
              <span class="inline-block px-2 py-1 font-semibold text-green-900 bg-green-200 rounded-full">
                MongoDB
              </span>
              <span class="inline-block px-2 py-1 font-semibold text-pink-900 bg-pink-200 rounded-full">
                Css
              </span>
              <span class="inline-block px-2 py-1 font-semibold text-blue-900 bg-blue-200 rounded-full">
                Tailwind
              </span>
              <span class="inline-block px-2 py-1 font-semibold text-yellow-900 bg-yellow-200 rounded-full">
                Boostrap
              </span>
              <span class="inline-block px-2 py-1 font-semibold text-red-900 bg-red-200 rounded-full">
                Firebase
              </span>
              <span class="inline-block px-2 py-1 font-semibold text-green-900 bg-green-200 rounded-full">
                Git
              </span>
              <span class="inline-block px-2 py-1 font-semibold text-black bg-gray-200 rounded-full">
                Trello
              </span>
              <span class="inline-block px-2 py-1 font-semibold text-green-800 bg-green-400 rounded-full">
                Vscode
              </span>
            </div>
            <section class="text-center py-12 px-4">
              <h2 class="text-2xl font-bold">{t("Mission et valeurs")} </h2>
              <p class="mt-4 dark:text-gray-400 text-gray-800 max-w-2xl mx-auto">
                {t(
                  "Ma mission est de fournir des services numériques exceptionnels en mettant l accent sur la disponibilité, la fiabilité et l'assistance."
                )}
              </p>
              <div class="flex justify-center space-x-8 mt-8 animate-fadeIn">
                <div class="transition transform hover:scale-110">
                  <h3 class="text-xl font-bold">250+</h3>
                  <p class="text-gray-800 dark:text-gray-400">
                    {t("Projets réalisés")}{" "}
                  </p>
                </div>
                <div class="transition transform hover:scale-110">
                  <h3 class="text-xl font-bold">09+</h3>
                  <p class="text-gray-800 dark:text-gray-400">
                    {t("Années d'expériences")}{" "}
                  </p>
                </div>
              </div>
            </section>
          </div>
        </div>
        {/* Futured */}
        <section>
          <div class="px-8 py-24  mx-auto md:px-12 lg:px-32 max-w-screen-2xl flex flex-col lg:h-svh justify-center">
            <div class="flex flex-col">
              <div class="num prose text-gray-500 mt-24 prose-sm  prose-headings:font-normal prose-headings:text-xl">
                <div>
                  <div class="flex rounded-full  py-1 w-48 px-4 font-medium border bg-gray-500 dark:bg-gray-200 border-gray-300">
                    <h1 className="prose text-white dark:text-gray-900 mx-auto">
                      {t("Projets numériques")}
                    </h1>
                  </div>

                  <p class="text-balance text-gray-800 dark:text-gray-100  -mb-2 p-4">
                    {t(
                      "Transformez le monde numérique avec des solutions innovantes..."
                    )}
                  </p>
                </div>
              </div>
              <div class="mt-6 border-t dark:border-gray-200 border-gray-800 -pt-12">
                <div class="grid grid-cols-1 gap-8 md:grid-cols-2 md:gap-24 items-center ">
                  <div>
                    {" "}
                    <span class="text-gray-600  uppercase text-xs font-medium ">
                      <div class="flex rounded-full -mt-8 w-48 py-1 px-4 font-medium border bg-white border-gray-300">
                        {" "}
                        {t("Faites la différence")}{" "}
                      </div>{" "}
                    </span>
                    <p class="text-3xl mt-8 tracking-tighter font-semibold text-blue-500 text-balance">
                      {" "}
                      {t(
                        "Gagnez un avantage concurrentiel grâce à l'innovation numérique."
                      )}{" "}
                    </p>
                    <p class="text-sm mt-4 text-gray-800 dark:text-gray-400  text-balance">
                      {" "}
                      {t(
                        "Optimisez vos opérations, améliorez l'efficacité et stimulez la croissance grâce à l'innovation numérique. Démarquez-vous avec des solutions innovantes. Méthodologies centrées sur l'utilisateur, design thinking"
                      )}
                    </p>
                    <div class="mt-6 text-xs font-medium grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 gap-2 text-gray-950">
                      <div class="inline-flex items-center gap-2 text-xs text-gray-700">
                        {" "}
                        <svg
                          class="icon icon-tabler text-gray-700 size-4 icon-tabler-360"
                          viewBox="0 0 24 24"
                          stroke-width="2"
                          stroke="currentColor"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path
                            stroke="none"
                            d="M0 0h24v24H0z"
                            fill="none"
                          ></path>
                          <path d="M17 15.328c2.414 -.718 4 -1.94 4 -3.328c0 -2.21 -4.03 -4 -9 -4s-9 1.79 -9 4s4.03 4 9 4"></path>
                          <path d="M9 13l3 3l-3 3"></path>
                        </svg>{" "}
                        <span class="text-gray-950 dark:text-white font-medium text-sm">
                          {" "}
                          {t("Site vitrine")}{" "}
                        </span>{" "}
                      </div>
                      <div class="inline-flex items-center gap-2  text-xs text-gray-700">
                        {" "}
                        <svg
                          class="icon icon-tabler text-gray-700 size-4 icon-tabler-antenna-bars-3"
                          viewBox="0 0 24 24"
                          stroke-width="2"
                          stroke="currentColor"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path
                            stroke="none"
                            d="M0 0h24v24H0z"
                            fill="none"
                          ></path>
                          <path d="M6 18l0 -3"></path>
                          <path d="M10 18l0 -6"></path>
                          <path d="M14 18l0 .01"></path>
                          <path d="M18 18l0 .01"></path>
                        </svg>{" "}
                        <span class="text-gray-950 dark:text-white font-medium text-sm">
                          {" "}
                          {t("Application web/Mobile")}{" "}
                        </span>{" "}
                      </div>
                      <div class="inline-flex items-center gap-2  text-xs text-gray-700">
                        {" "}
                        <svg
                          class="icon icon-tabler text-gray-700 size-4 icon-tabler-load-balancer"
                          viewBox="0 0 24 24"
                          stroke-width="2"
                          stroke="currentColor"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path
                            stroke="none"
                            d="M0 0h24v24H0z"
                            fill="none"
                          ></path>
                          <path d="M12 13m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"></path>
                          <path d="M12 20m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0"></path>
                          <path d="M12 16v3"></path>
                          <path d="M12 10v-7"></path>
                          <path d="M9 6l3 -3l3 3"></path>
                          <path d="M12 10v-7"></path>
                          <path d="M9 6l3 -3l3 3"></path>
                          <path d="M14.894 12.227l6.11 -2.224"></path>
                          <path d="M17.159 8.21l3.845 1.793l-1.793 3.845"></path>
                          <path d="M9.101 12.214l-6.075 -2.211"></path>
                          <path d="M6.871 8.21l-3.845 1.793l1.793 3.845"></path>
                        </svg>{" "}
                        <span class="text-gray-950 dark:text-white font-medium text-sm">
                          {" "}
                          {t("Digitalisation des outils")}{" "}
                        </span>{" "}
                      </div>
                      <div class="inline-flex items-center gap-2  text-xs text-gray-700">
                        {" "}
                        <svg
                          class="icon icon-tabler text-gray-700 size-4 icon-tabler-brand-speedtest"
                          viewBox="0 0 24 24"
                          stroke-width="2"
                          stroke="currentColor"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path
                            stroke="none"
                            d="M0 0h24v24H0z"
                            fill="none"
                          ></path>
                          <path d="M5.636 19.364a9 9 0 1 1 12.728 0"></path>
                          <path d="M16 9l-4 4"></path>
                        </svg>{" "}
                        <span class="text-gray-950 dark:text-white font-medium text-sm">
                          {" "}
                          Collecte de données...{" "}
                        </span>{" "}
                      </div>
                    </div>
                  </div>
                  <div class="h-full md:order-first -mt-8">
                    {" "}
                    <img
                      src=".././Full-Stack.webp"
                      alt="numérique"
                      class="cursor-pointer dark:bg-black py-6 rounded-lg transform transition duration-500 hover:scale-110 bg-gray-200 shadow-box shadow-gray-500/30 overflow-hidden aspect-square  w-full h-full object-cover object-center"
                    />{" "}
                  </div>
                </div>
                <div class="grid grid-cols-1 gap-8 md:grid-cols-2  items-center md:flex-row-reverse">
                  <div>
                    {" "}
                    <span class="text-gray-600  uppercase text-xs font-medium ">
                      {" "}
                      <div class="flex rounded-full w-48 py-1 px-4 font-medium border bg-white border-gray-300">
                        {" "}
                        {t("Marketing Digital")}{" "}
                      </div>{" "}
                    </span>
                    <p class="text-3xl mt-8 tracking-tighter font-semibold text-blue-500 text-balance">
                      {" "}
                      {t(
                        "Gardez une longueur d'avance sur la concurrence et dominez votre présence en ligne."
                      )}{" "}
                      {/* L'innovation, c'est notre passion  */}
                    </p>
                    <p class="text-sm  mt-4 text-gray-800 dark:text-gray-400 text-balance">
                      {" "}
                      {t(
                        "Renforcer les relations avec les clients et améliorer la fidélité de votre marque. Attirez un trafic ciblé vers votre site web et augmentez la notoriété de votre marque."
                      )}
                    </p>
                    <div class="mt-6 text-xs font-medium grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 gap-2 text-gray-950">
                      <div class="inline-flex items-center gap-2  text-xs text-gray-700">
                        {" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="icon icon-tabler icon-tabler-database text-gray-700 size-4"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path
                            stroke="none"
                            d="M0 0h24v24H0z"
                            fill="none"
                          ></path>
                          <path d="M12 6m-8 0a8 3 0 1 0 16 0a8 3 0 1 0 -16 0"></path>
                          <path d="M4 6v6a8 3 0 0 0 16 0v-6"></path>
                          <path d="M4 12v6a8 3 0 0 0 16 0v-6"></path>
                        </svg>{" "}
                        <span class="text-gray-950 dark:text-white font-medium text-sm">
                          {" "}
                          {t("Web Design")}{" "}
                        </span>{" "}
                      </div>
                      <div class="inline-flex items-center gap-2  text-xs text-gray-700">
                        {" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="icon icon-tabler icon-tabler-building text-gray-700 size-4"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path
                            stroke="none"
                            d="M0 0h24v24H0z"
                            fill="none"
                          ></path>
                          <path d="M3 21l18 0"></path>
                          <path d="M9 8l1 0"></path>
                          <path d="M9 12l1 0"></path>
                          <path d="M9 16l1 0"></path>
                          <path d="M14 8l1 0"></path>
                          <path d="M14 12l1 0"></path>
                          <path d="M14 16l1 0"></path>
                          <path d="M5 21v-16a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v16"></path>
                        </svg>{" "}
                        <span class="text-gray-950 dark:text-white font-medium text-sm">
                          {" "}
                          {t("Conception graphique/Vidéo")}{" "}
                        </span>{" "}
                      </div>
                      <div class="inline-flex items-center gap-2  text-xs text-gray-700">
                        {" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="icon icon-tabler icon-tabler-augmented-reality-2 text-gray-700 size-4"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path
                            stroke="none"
                            d="M0 0h24v24H0z"
                            fill="none"
                          ></path>
                          <path d="M10 21h-2a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v3.5"></path>
                          <path d="M17 17l-4 -2.5l4 -2.5l4 2.5v4.5l-4 2.5z"></path>
                          <path d="M13 14.5v4.5l4 2.5"></path>
                          <path d="M17 17l4 -2.5"></path>
                          <path d="M11 4h2"></path>
                        </svg>{" "}
                        <span class="text-gray-950 dark:text-white font-medium text-sm">
                          {" "}
                          Facebook/Google ads ...{" "}
                        </span>{" "}
                      </div>
                      <div class="inline-flex items-center gap-2  text-xs text-gray-700">
                        {" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="icon icon-tabler icon-tabler-time-duration-0 text-gray-700 size-4"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path
                            stroke="none"
                            d="M0 0h24v24H0z"
                            fill="none"
                          ></path>
                          <path d="M3 12v.01"></path>
                          <path d="M21 12v.01"></path>
                          <path d="M12 21v.01"></path>
                          <path d="M12 3v.01"></path>
                          <path d="M7.5 4.2v.01"></path>
                          <path d="M16.5 4.2v.01"></path>
                          <path d="M16.5 19.8v.01"></path>
                          <path d="M7.5 19.8v.01"></path>
                          <path d="M4.2 16.5v.01"></path>
                          <path d="M19.8 16.5v.01"></path>
                          <path d="M19.8 7.5v.01"></path>
                          <path d="M4.2 7.5v.01"></path>
                          <path d="M10 11v2a2 2 0 1 0 4 0v-2a2 2 0 1 0 -4 0z"></path>
                        </svg>{" "}
                        <span class="text-gray-950 dark:text-white font-medium text-sm">
                          {" "}
                          Marketing Digital{" "}
                        </span>{" "}
                      </div>
                    </div>
                  </div>
                  <div class="h-full ">
                    {" "}
                    <img
                      src=".././digi.webp"
                      alt="#_"
                      class="cursor-pointer py-6  
                                transform transition duration-500 
                                hover:scale-110 bg-gray-200 shadow-box rounded-lg shadow-gray-500/30 overflow-hidden aspect-square dark:bg-black w-full h-full object-cover object-center"
                    />{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Futured  */}
      </body>
      <section class="touch pt-32 bg-gray-200 dark:bg-black sm:pt-80">
        <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div class="text-center">
            <p class="max-w-4xl mx-auto dark:text-gray-100 mb-4 text-3xl font-bold leading-tight text-gray-900 sm:leading-tight sm:text-3xl lg:text-4xl lg:leading-tight">
              {t(
                "Une idée de projet ou une collaboration ? Travaillons ensemble !"
              )}
            </p>
            <h1 class="max-w-2xl mx-auto px-6 text-lg text-gray-600 font-inter">
              {t(
                "Je suis à votre disposition 24/24 pour répondre à vos préoccupations et besoins et vous soutenir dans votre processus de transformation digitale."
              )}
            </h1>
            <div className="justify-center py-8 items-center">
              <div class="flex flex-col sm:flex-row w-64  mx-auto">
                <img
                  src=".././Digital.jpg"
                  class="rounded-xl  rotate-6 hover:rotate-0 duration-500 hover:-translate-y-12 h-full w-full object-cover hover:scale-150 transform origin-bottom"
                  alt="#_"
                />
                <img
                  src=".././Web_mobile.png "
                  class="rounded-xl  -rotate-12 hover:rotate-0 duration-500 hover:-translate-y-12 h-full w-full object-cover hover:scale-150 transform origin-bottom"
                  alt="#_"
                />
                <img
                  src=".././api.webp"
                  class="rounded-xl  rotate-6 hover:rotate-0 duration-500 hover:-translate-y-12 h-full w-full object-cover hover:scale-150 transform origin-bottom"
                  alt="#_"
                />{" "}
                <img
                  src=".././graphique.png"
                  class="rounded-xl  -rotate-12 hover:rotate-0 duration-500 hover:-translate-y-12 h-full w-full object-cover hover:scale-150 transform origin-bottom"
                  alt="#_"
                />
              </div>
            </div>
            <div class="px-8 sm:items-start sm:justify-center dark:bg-black sm:px-0 sm:space-x-5 sm:flex mt-9">
              <a
                href="https://api.whatsapp.com/send/?phone=22995898072&text=Hi Lynos"
                title="Chat WhatsApp"
                target="_blank"
                class="mb-3 sm:mb-0 inline-flex items-center justify-center w-full px-8 py-3 text-lg font-bold text-white transition-all duration-200 bg-gray-900 border-2 border-transparent sm:w-auto rounded-xl hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                role="button"
              >
                Inbox ⇾
              </a>
              {/* <a
                href="#"
                class="inline-flex items-center justify-center w-full px-8 py-3 text-lg font-bold text-gray-900 hover:text-white transition-all duration-200 bg-gray-100 border-2 border-gray-900 sm:w-auto rounded-xl hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                role="button"
              >
                Book a demo
              </a> */}
            </div>
          </div>
        </div>
        <div class="bg-gray-200 dark:black">
          <div class="relative mx-auto mt-4 dark:bg-black md:mt-8">
            {/* <div class="lg:max-w-4xl lg:mx-auto dark:bg-black rounded-lg">
              <img
                class="px-4 md:mpx-8 mx-auto rounded-full"
                src=".././Digital.jpg"
              />
            </div> */}
          </div>
        </div>
      </section>
      <section
        id="portfolio"
        class="portfolio-section dark:bg-black bg-gray-200 -mt-32  py-16  px-4"
      >
        <div class="container mx-auto mt-48">
          <div id="results" class="text-center mb-12">
            <h2 class="text-4xl font-bold dark:text-white -mt-24 mb-4">
              {t("Projets réalisés")}
            </h2>
            <p class="title-text text-lg text-gray-500 px-48 dark:text-white font-semibold">
              {t(
                "Ce portfolio présente une sélection de mes projets les plus récents et les plus réussis. Chaque projet met en lumière mes compétences et ma capacité à créer des solutions web sur mesure qui répondent aux besoins spécifiques de mes clients."
              )}
            </p>
          </div>
          <div class="flex flex-col md:flex-row items-center mb-8">
            <button
              onClick={() =>
                setItems([
                  {
                    id: 1,
                    name: "Plateforme d'envoie de SMS",
                    category: "A",
                    url: "https://spacema-dev.com/elevate/assets/images/portfolio-1.png",
                    redirect: "https://5sender.com/",
                  },
                  {
                    id: 2,
                    name: "Plateforme de WhatsApp Booking",
                    category: "B",
                    url: "https://spacema-dev.com/elevate/assets/images/portfolio-2.png",
                    redirect: "https://wasenderpro.5sender.com/",
                  },
                  {
                    id: 3,
                    name: "Plateforme de vente de compte Netflix",
                    category: "A",
                    url: "https://spacema-dev.com/elevate/assets/images/portfolio-3.png",
                    redirect: "https://goflix.pro/",
                  },
                  {
                    id: 4,
                    name: "E-commerce",
                    category: "C",
                    url: "https://spacema-dev.com/elevate/assets/images/portfolio-4.png",
                    redirect: "https://services.goflix.pro/",
                  },
                  {
                    id: 5,
                    name: "Site vitrine STIC CONGO",
                    category: "C",
                    url: "https://spacema-dev.com/elevate/assets/images/portfolio-5.png",
                    redirect: "https://stic-congo.com/",
                  },
                  {
                    id: 6,
                    name: "Site vitrine FISA BENIN",
                    category: "C",
                    url: "https://spacema-dev.com/elevate/assets/images/portfolio-6.png",
                    redirect: "https://fisa-benin.5sender.com/",
                  },
                ])
              }
              class="filter-button bg-indigo-500 hover:bg-pink-500 px-4 py-2 mr-2 mb-2 text-white rounded"
            >
              Tout
            </button>

            {/* <button
              onClick={() =>
                setItems([
                  {
                    id: 1,
                    name: "Item 1",
                    category: "A",
                    url: "https://spacema-dev.com/elevate/assets/images/portfolio-1.png",
                    redirect: "#",
                  },
                  {
                    id: 2,
                    name: "Item 2",
                    category: "B",
                    url: "https://spacema-dev.com/elevate/assets/images/portfolio-2.png",
                    redirect: "#",
                  },
                  {
                    id: 3,
                    name: "Item 3",
                    category: "A",
                    url: "https://spacema-dev.com/elevate/assets/images/portfolio-3.png",
                    redirect: "#",
                  },
                  {
                    id: 4,
                    name: "Item 4",
                    category: "C",
                    url: "https://spacema-dev.com/elevate/assets/images/portfolio-4.png",
                    redirect: "#",
                  },
                ])
              }
              class="filter-button  bg-indigo-500 hover:bg-pink-500 px-4 py-2 mr-2 mb-2 text-white rounded"
            >
              Web Design
            </button> */}
            <button
              onClick={() =>
                setItems([
                  {
                    id: 1,
                    name: "Plateforme d'envoie de SMS",
                    category: "A",
                    url: "https://spacema-dev.com/elevate/assets/images/portfolio-1.png",
                    redirect: "https://5sender.com/",
                  },
                  {
                    id: 2,
                    name: "Plateforme de WhatsApp Booking",
                    category: "B",
                    url: "https://spacema-dev.com/elevate/assets/images/portfolio-2.png",
                    redirect: "https://wasenderpro.5sender.com/",
                  },
                  {
                    id: 3,
                    name: "Plateforme de vente de compte Netflix",
                    category: "A",
                    url: "https://spacema-dev.com/elevate/assets/images/portfolio-3.png",
                    redirect: "https://goflix.pro/",
                  },
                ])
              }
              class="filter-button  bg-indigo-500 hover:bg-pink-500 px-4 py-2 mr-2 mb-2 text-white rounded"
            >
              Application web & mobile
            </button>
            <button
              onClick={() =>
                setItems([
                  {
                    id: 5,
                    name: "Site vitrine STIC CONGO",
                    category: "C",
                    url: "https://spacema-dev.com/elevate/assets/images/portfolio-5.png",
                    redirect: "https://stic-congo.com/",
                  },
                  {
                    id: 6,
                    name: "Site vitrine FISA BENIN",
                    category: "C",
                    url: "https://spacema-dev.com/elevate/assets/images/portfolio-6.png",
                    redirect: "https://fisa-benin.5sender.com/",
                  },
                ])
              }
              class="filter-button  bg-indigo-500 hover:bg-pink-500 px-4 py-2 mr-2 mb-2 text-white rounded"
            >
              Site vitrine
            </button>
          </div>

          <div class="folio grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
            {items.map((item) => (
              <div
                key={item.id}
                class="group portfolio-item relative hover:shadow-lg dark:text-white  shadow-md rounded-lg overflow-hidden"
              >
                <a href={item.redirect}>
                  <img
                    class="w-full h-60 object-cover"
                    src={item.url}
                    alt="Project 1"
                  />
                  <div class="absolute top-0 left-0 right-0 bottom-0 bg-gradient-to-r from-indigo-500 to-pink-500 opacity-0 transition duration-300 ease-in-out group-hover:opacity-70"></div>
                  <div class="p-4 flex flex-col items-center justify-between relative z-10">
                    <h3 class="text-lg font-medium text-txt group-hover:text-gray-dark">
                      {item.name}
                    </h3>
                    <span class="text-sm font-bold rounded-lg text-center border-2 w-24 text-blue-500 group-hover:text-indigo-500">
                      online ⇾
                    </span>
                  </div>
                </a>
              </div>
            ))}
          </div>
        </div>
        <div id="contact"></div>
        <div class="max-w-screen-xl mx-auto my-7 mt-32 lg:px-20   dark:bg-black">
          <form id="contact" onSubmit={ContactSubmit}>
            <div class="w-full bg-black p-8 my-4 mr-auto dark:border border-2 shadow-2xl md:px-12 lg:w-9/12 lg:pl-20 lg:pr-40 rounded-2xl">
              <div class="flex">
                <h1 class="text-5xl text-blue-500 font-bold ">
                  {t("Me joindre ?")}{" "}
                </h1>
              </div>
              <div className="text-center text-red-400 p-4">{errormsg}</div>
              <div className="text-center text-green-400 p-4">{succesmsg}</div>

              <div class="grid grid-cols-1 gap-5 mt-5 md:grid-cols-2">
                <input
                  class="w-full p-3 mt-2 outline outline-gray-200 text-gray-900 bg-gray-100 rounded-lg focus:outline-none focus:border-outline"
                  type="text"
                  name="firstname"
                  placeholder="Nom"
                  id="hs-firstname-contacts-1"
                  autocomplete="name"
                />
                <input
                  class="w-full p-3 mt-2 outline outline-gray-200 text-gray-900 bg-gray-100 rounded-lg focus:outline-none focus:shadow-outline"
                  type="text"
                  name="lastname"
                  placeholder="Prénom(s)"
                  id="hs-lastname-contacts-1"
                  autocomplete="family-name"
                />
                <input
                  class="w-full p-3 mt-2 text-gray-900 outline outline-gray-200 bg-gray-100 rounded-lg focus:outline-none focus:shadow-outline"
                  type="email"
                  name="email"
                  placeholder="E-mail"
                  id="hs-email-contacts-1"
                  autocomplete="email"
                />
                <input
                  class="w-full p-3 mt-2 outline outline-gray-200 text-gray-900 bg-gray-100 rounded-lg focus:outline-none focus:shadow-outline"
                  type="text"
                  name="phone"
                  id="hs-phone-number-1"
                  placeholder="Téléphone"
                  autocomplete="tel"
                />
              </div>
              <div class="my-4">
                <input
                  type="text"
                  placeholder="Ville/Pays"
                  class="w-full  p-3 mt-2 outline outline-gray-200 text-gray-900 bg-gray-100 rounded-lg focus:outline-none focus:shadow-outline"
                  name="country"
                  id="country"
                ></input>
              </div>
              <div class="my-4">
                <input
                  placeholder="objet"
                  class="w-full  p-3 mt-2 outline outline-gray-200 text-gray-900 bg-gray-100 rounded-lg focus:outline-none focus:shadow-outline"
                  type="text"
                  name="objet"
                  id="objet"
                  autocomplete="objet"
                ></input>
              </div>
              <div class="my-4">
                <textarea
                  placeholder="Message"
                  class="w-full h-32 p-3 mt-2 outline outline-gray-200 text-gray-900 bg-gray-100 rounded-lg focus:outline-none focus:shadow-outline"
                  id="msg"
                  name="msg"
                  autocomplete="msg"
                ></textarea>
              </div>
              <div class="flex w-1/2 my-2 lg:w-2/4">
                <button
                  enable={loading}
                  type="submit"
                  class="w-full p-3 text-sm outline outline-gray-200 font-bold tracking-wide text-gray-100 uppercase bg-blue-500 rounded rounded-xl hover:bg-blue-700-lg focus:outline-none focus:shadow-outline"
                >
                  {loading ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6 animate-spin mx-auto"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                      />
                    </svg>
                  ) : (
                    // "Envoyer ⇾"
                    ""
                  )}
                  {t("Envoyer ⇾")}
                </button>

                <input
                  class="inline ml-4 w-full p-3 text-sm outline outline-gray-200 font-bold
                tracking-wide text-gray-100 uppercase bg-blue-500 rounded
                rounded-xl hover:bg-blue-700-lg focus:outline-none
                focus:shadow-outline"
                  type="reset"
                  value="Reset 🐶"
                />
              </div>
            </div>
          </form>
          <div class="w-full px-2 mb-8 py-12 ml-auto bg-blue-500 lg:-mt-96 lg:w-2/6 rounded-2xl">
            <div class="flex flex-col text-white">
              <div class="flex w-2/3 my-4 lg:w-1/2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="pt-2 pr-2 h-7 w-7"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M20.8 9H12v8h-1v4H5a1 1 0 0 1-1-1v-9H1l10.327-9.388a1 1 0 0 1 1.346 0zM14 11h9v7h-9zm-1 10h11v-2H13z"
                  />
                </svg>
                <div>
                  <h2 class="text-2xl ">Adresse</h2>
                  <p class="text-gray-200">Abomey-Calavi, Bénin</p>
                </div>
              </div>
              <div class="flex w-2/3 my-4 lg:w-1/2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="pt-2 pr-2 h-7 w-7"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M16 11V8h-3V6h3V3h2v3h3v2h-3v3zm3.95 10q-3.125 0-6.175-1.362t-5.55-3.863t-3.862-5.55T3 4.05q0-.45.3-.75t.75-.3H8.1q.35 0 .625.238t.325.562l.65 3.5q.05.4-.025.675T9.4 8.45L6.975 10.9q.5.925 1.187 1.787t1.513 1.663q.775.775 1.625 1.438T13.1 17l2.35-2.35q.225-.225.588-.337t.712-.063l3.45.7q.35.1.575.363T21 15.9v4.05q0 .45-.3.75t-.75.3M6.025 9l1.65-1.65L7.25 5H5.025q.125 1.025.35 2.025T6.025 9m8.95 8.95q.975.425 1.988.675T19 18.95v-2.2l-2.35-.475zm0 0"
                  />
                </svg>
                <div>
                  <h2 class="text-2xl">Call</h2>
                  <p class="text-gray-200">Tel:+229 95 89 80 72</p>
                </div>
              </div>
              <div class="flex w-2/3 my-4 lg:w-1/2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="pt-2 pr-2 h-7 w-7"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="m20 8l-8 5l-8-5V6l8 5l8-5m0-2H4c-1.11 0-2 .89-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2"
                  />
                </svg>
                <div>
                  <h2 class="text-2xl flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="pt-2 pr-2 h-7 w-7"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="currentColor"
                        d="m20 8l-8 5l-8-5V6l8 5l8-5m0-2H4c-1.11 0-2 .89-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2"
                      />
                    </svg>
                    Email
                  </h2>
                  <p class="text-gray-200">
                    Email:komlanvi-lynos.hounsihoue@epitech.eu
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section class=" px-4 py-12 md:py-24">
          <div class="max-w-screen-xl mx-auto">
            <h2 class="font-black text-black dark:text-gray-100 text-center text-3xl leading-none uppercase max-w-2xl mx-auto mb-12">
              FeedBack des clients et partenaires
            </h2>
            <div class="flex flex-col space-y-4 md:space-y-0 md:flex-row md:space-x-4 relative">
              <div class="bg-gray-200 rounded-lg p-8 text-center md:w-1/3">
                <p class="font-bold uppercase">Jéffrey H, STIC CONGO</p>
                <p class="text-xl font-light italic text-gray-700">
                  {t(
                    "Nous sommes particulièrement satisfaits de la capacité à respecter les délais. Nous recommandons vivement Lynos à toutes les entreprises qui recherchent un partenaire fiable et expérimenté pour la création d'applications web."
                  )}
                </p>
                <div class="flex items-center justify-center space-x-2 mt-4">
                  <svg
                    class="text-yellow-500 w-4 h-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    stroke="currentColor"
                  >
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                  </svg>
                  <svg
                    class="text-yellow-500 w-4 h-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    stroke="currentColor"
                  >
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                  </svg>
                  <svg
                    class="text-yellow-500 w-4 h-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    stroke="currentColor"
                  >
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                  </svg>
                  <svg
                    class="text-yellow-500 w-4 h-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    stroke="currentColor"
                  >
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                  </svg>
                  <svg
                    class="text-yellow-500 w-4 h-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    stroke="currentColor"
                  >
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                  </svg>
                </div>
              </div>
              <div class="bg-gray-200 rounded-lg p-8 text-center md:w-1/3">
                <p class="font-bold uppercase">Médéssin A, EEO France</p>
                <p class="text-xl font-light italic text-gray-700">
                  {t(
                    "Nous recommandons vivement Lynos à toutes les entreprises qui recherchent un partenaire fiable et axé sur le client pour la conception graphique & Branding et le développement d'applications web."
                  )}
                </p>
                <div class="flex items-center justify-center space-x-2 mt-4">
                  <svg
                    class="text-yellow-500 w-4 h-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    stroke="currentColor"
                  >
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                  </svg>
                  <svg
                    class="text-yellow-500 w-4 h-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    stroke="currentColor"
                  >
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                  </svg>
                  <svg
                    class="text-yellow-500 w-4 h-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    stroke="currentColor"
                  >
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                  </svg>
                  <svg
                    class="text-yellow-500 w-4 h-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    stroke="currentColor"
                  >
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                  </svg>
                </div>
              </div>
              <div class="bg-gray-200 rounded-lg p-8 text-center md:w-1/3">
                <p class="font-bold uppercase">
                  Felix D, Champion Evennementiel Bénin
                </p>
                <p class="text-xl font-light italic text-gray-700">
                  {t(
                    "Il prend le temps de comprendre nos besoins et de concevoir des applications qui répondent parfaitement à nos attentes. Nous sommes particulièrement satisfaits de sa réactivité et de son professionnalisme."
                  )}
                </p>
                <div class="flex items-center justify-center space-x-2 mt-4">
                  <svg
                    class="text-yellow-500 w-4 h-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    stroke="currentColor"
                  >
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                  </svg>
                  <svg
                    class="text-yellow-500 w-4 h-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    stroke="currentColor"
                  >
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                  </svg>
                  <svg
                    class="text-yellow-500 w-4 h-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    stroke="currentColor"
                  >
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3 .921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784 .57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81 .588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                  </svg>
                  <svg
                    class="text-yellow-500 w-4 h-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    stroke="currentColor"
                  >
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3 .921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784 .57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81 .588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div className="tesmo">
            <Testimonial />
          </div>
        </section>

        {/* <section class="flex  flex-col lg:h-svh justify-center items-center overflow-scroll"> */}
        <section class="flex  flex-col lg:h-svh justify-center items-center ">
          <div class="text-gray-500 prose-sm prose-headings:font-normal prose-headings:text-xl">
            <div>
              <h2 class="text-3xl font-bold text-center sm:text-5xl">
                {t("Envies & Passions")}
              </h2>
              <p class="max-w-3xl mx-auto mt-4 text-xl text-center ">
                {t(
                  "Il n'y pas que le code ! Une vie pleine de sens mérite des moments de rencontres, de partages, d'amusements et de débordements pour aller au-delà des limites de soi même."
                )}{" "}
              </p>
            </div>
          </div>
          <div class="marg flex flex-wrap -mr-48 md:flex-nowrap mt-6 border-gray-800 dark:border-gray-100 border-t pt-12">
            <div class="relative flex flex-col items-start m-1 transition  ease-in-out duration-500  delay-150 transform  md:w-96 md:-ml-32 md:hover:-translate-x-32 md:hover:-translate-y-8 shrink-0 ">
              <article
                class="mx-auto   rounded-3xl overflow-hidden  bg-cover ring-2 ring-inset ring-white/50 bg-center min-h-150 relative   transform duration-500 
                group bg-[url('F:\lynosdigiWeb\portofolio\public\IMG-20201107-WA0023.jpg')]"
              >
                <div class=" relative h-full  group-hover:bg-opacity-0 min-h-150  flex flex-wrap flex-col pt-[30rem]  transform duration-500">
                  <div class=" group-hover:bg-black/30 duration-500 group-hover:backdrop-blur p-8 lg:p-10 h-full justify-end flex flex-col ">
                    <p class="opacity-0 text-white text-sm 2xl:text-lg group-hover:opacity-80 transform duration-500 ">
                      {" "}
                      Un moment de rafraîchissement et de rencontres{" "}
                    </p>
                  </div>
                </div>
              </article>
            </div>
            <div class="relative flex flex-col items-start m-1 transition  ease-in-out duration-500  delay-150 transform  md:w-96 md:-ml-32 md:hover:-translate-x-32 md:hover:-translate-y-8 shrink-0 ">
              <article
                class="mx-auto   rounded-3xl overflow-hidden  bg-cover ring-2 ring-inset ring-white/50 bg-center min-h-150 relative   transform duration-500 
                group bg-[url('https://www.lynoshk.com/IMG-20230421-WA0041.jpg')]"
              >
                <div class=" relative h-full  group-hover:bg-opacity-0 min-h-150  flex flex-wrap flex-col pt-[30rem]  transform duration-500">
                  <div class=" group-hover:bg-black/30 duration-500 group-hover:backdrop-blur p-8 lg:p-10 h-full justify-end flex flex-col ">
                    <p class="opacity-0 text-white text-sm 2xl:text-lg group-hover:opacity-80 transform duration-500 ">
                      {" "}
                      Sorie touristique pour à la découverte...{" "}
                    </p>
                  </div>
                </div>
              </article>
            </div>
            <div class="relative flex flex-col items-start m-1 transition  ease-in-out duration-500  delay-150 transform  md:w-96 md:-ml-32 md:hover:-translate-x-32 md:hover:-translate-y-8 shrink-0 ">
              <article
                class="mx-auto   rounded-3xl overflow-hidden  bg-cover ring-2 ring-inset ring-white/50 bg-center min-h-150 relative   transform duration-500 
                group bg-[url('https://www.lynoshk.com/20230402_203824.jpg')]"
              >
                <div class=" relative h-full  group-hover:bg-opacity-0 min-h-150  flex flex-wrap flex-col pt-[30rem]  transform duration-500">
                  <div class=" group-hover:bg-black/30 duration-500 group-hover:backdrop-blur p-8 lg:p-10 h-full justify-end flex flex-col ">
                    <p class="opacity-0 text-white text-sm 2xl:text-lg group-hover:opacity-80 transform duration-500 ">
                      {" "}
                      Un peu de distration pour se ressourcer{" "}
                    </p>
                  </div>
                </div>
              </article>
            </div>
            <div class="relative flex flex-col items-start m-1 transition  ease-in-out duration-500  delay-150 transform  md:w-96 md:-ml-32 md:hover:-translate-x-32 md:hover:-translate-y-8 shrink-0 ">
              <article
                class="mx-auto   rounded-3xl overflow-hidden  bg-cover ring-2 ring-inset ring-white/50 bg-center min-h-150 relative   transform duration-500   
              group bg-[url('https://www.lynoshk.com/20230616_125326.jpg')]"
              >
                <div class=" relative h-full  group-hover:bg-opacity-0 min-h-150  flex flex-wrap flex-col pt-[30rem]  transform duration-500">
                  <div class=" group-hover:bg-black/30 duration-500 group-hover:backdrop-blur p-8 lg:p-10 h-full justify-end flex flex-col ">
                    <p class="opacity-0 text-white text-sm 2xl:text-lg group-hover:opacity-80 transform duration-500 ">
                      {" "}
                      Prêt pour relever le défi & le challenge{" "}
                    </p>
                  </div>
                </div>
              </article>
            </div>
            <div class="relative flex flex-col items-start m-1 transition  ease-in-out duration-500  delay-150 transform  md:w-96 md:-ml-32 md:hover:-translate-x-32 md:hover:-translate-y-8 shrink-0 ">
              <article
                class="mx-auto   rounded-3xl overflow-hidden  bg-cover ring-2 ring-inset ring-white/50 bg-center min-h-150 relative   transform duration-500  
               group bg-[url('https://www.lynoshk.com/aIMG_8590.jpg')]"
              >
                {/* group bg-[url('F:\lynosdigiWeb\portofolio\public\aIMG_8590.jpg')]" */}
                <div class=" relative h-full  group-hover:bg-opacity-0 min-h-150  flex flex-wrap flex-col pt-[30rem]  transform duration-500">
                  <div class=" group-hover:bg-black/30 duration-500 group-hover:backdrop-blur p-8 lg:p-10 h-full justify-end flex flex-col ">
                    <p class="opacity-0 text-white text-sm 2xl:text-lg group-hover:opacity-80 transform duration-500 ">
                      {" "}
                      On remet tout dans la main du Père{" "}
                    </p>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </section>

        {/* Modal */}
        {showModal ? (
          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative w-auto my-6 mx-auto max-w-3xl">
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  {/*header*/}
                  <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                    <h3 className="text-3xl font-semibold">
                      Veuillez saisir votre E-mail 👁‍🗨
                    </h3>
                    <button
                      className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={() => setShowModal(false)}
                    >
                      <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                        ×
                      </span>
                    </button>
                  </div>
                  {/*body*/}
                  <div className="p-6 flex-auto">
                    <p className="my-4 text-slate-500 text-lg leading-relaxed">
                      Bonjour Mme/M., je vous remercie pour l'interêt que vous
                      portez à mon profil. Veuillez entrer votre E-mail valide
                      pour recevoir le code de téléchargement de mon CV. Merci
                      🔑
                    </p>
                  </div>
                  <form>
                    <div class="mb-4 px-8   ">
                      <p className="mb-4 -mt-4 text-center">
                        {" "}
                        {error && <h2 style={{ color: "red" }}>{error}</h2>}
                      </p>

                      <input
                        type="email"
                        value={userEmail}
                        onChange={(e) => setUserEmail(e.target.value)}
                        class="shadow appearance-none border rounded w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="email"
                        placeholder="Votre e-mail ici 📧"
                        required
                      />
                    </div>
                    {/*footer*/}
                    <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                      <button
                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={() => setShowModal(false)}
                      >
                        Fermer
                      </button>
                      <button
                        enable={loading}
                        className="bg-blue-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={getEmail}
                      >
                        {" "}
                        {loading ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6 animate-spin mx-auto"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                            />
                          </svg>
                        ) : (
                          "Valider"
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </section>
      <Footer />
    </>
  );
}

export default Accueil;
