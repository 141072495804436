// src/WhatsAppWidget.js
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import './WhatsAppWidget.css';

const WhatsAppWidget = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleChat = () => {
    setIsOpen(!isOpen);
  };

  const phoneNumber = '22995898072'; // Replace with your WhatsApp number

  return (
    <div className="whatsapp-widget ">
      <button className="whatsapp-button animate-bounce " onClick={toggleChat}>
        <FontAwesomeIcon icon={faWhatsapp} size="2x" />
      </button>
      {isOpen && (
        <div className="whatsapp-chat">
          <div className="chat-header">
            <h4>Chat avec MOI sur WhatsApp</h4>
            <button onClick={toggleChat}>X</button>
          </div>
          <div className="chat-body">
            <a
              href={`https://wa.me/${phoneNumber}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Entamez une conversation ⇾
            </a>
          </div>
        </div>
      )}
      <span class="relative flex h-4 w-4">
        <a
          href={`https://wa.me/${phoneNumber}`}
          target="_blank"
          class="relative inline-flex rounded-lg h-8 -mt-2 mr-2 -ml-32 px-4 text-white w-32 bg-green-500"
        >
          Discussion ?
        </a>
        <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75"></span>
        <span class="relative inline-flex rounded-full h-4 w-4 bg-green-500"></span>
      </span>
    </div>
  );
};

export default WhatsAppWidget;
