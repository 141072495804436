import { useState, useEffect } from "react";

function Confirmation() {
  const [showConfirm, setShowConfirm] = useState(true);
  const [code, setCode] = useState("");
  const [errorMsg, setErrorMsg] = useState("");


function getCode(){
  let validCode = localStorage.getItem("xtrem");
  let decodedString = atob(validCode);
// console.log(decodedString);
// console.log(code);
  // Output: Code!
  if (code === decodedString) {
    window.open(".././CV Lynos Certif.pdf", "_blank");
    setTimeout(()=>{
      window.location.replace("/");
    },1000)
    
  }else if(code===""){
    setErrorMsg("Veuillez saisir le code de téléchargement");
    setTimeout(() => {
      setErrorMsg("");
    }, 2000);
  }else if(code!==decodedString){
       setErrorMsg("Le code de Téchargement est incorrect !");
       setTimeout(() => {
         setErrorMsg("");
       }, 2000);
  }

}
  // useEffect(() => {
  //   setShowConfirm(true);
  // }, []);

  return (
    <div>
      {showConfirm ? (
        <div class="fixed z-50 inset-0 bg-gray-900 bg-opacity-60 overflow-y-auto h-full w-full px-4 ">
          <div class="relative top-40 mx-auto shadow-xl rounded-md bg-white max-w-md">
            <div class="flex justify-end p-2">
              <button
                onclick="closeModal('modelConfirm')"
                type="button"
                class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
              >
                <svg
                  class="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>

            <div class="p-6 pt-0 text-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-20 h-20 text-red-600 mx-auto"
                viewBox="0 0 24 24"
              >
                <rect width="6" height="14" x="1" y="4" fill="currentColor">
                  <animate
                    id="svgSpinnersBarsScaleFade0"
                    fill="freeze"
                    attributeName="y"
                    begin="0;svgSpinnersBarsScaleFade1.end-0.25s"
                    dur="0.75s"
                    values="1;5"
                  />
                  <animate
                    fill="freeze"
                    attributeName="height"
                    begin="0;svgSpinnersBarsScaleFade1.end-0.25s"
                    dur="0.75s"
                    values="22;14"
                  />
                  <animate
                    fill="freeze"
                    attributeName="opacity"
                    begin="0;svgSpinnersBarsScaleFade1.end-0.25s"
                    dur="0.75s"
                    values="1;0.2"
                  />
                </rect>
                <rect
                  width="6"
                  height="14"
                  x="9"
                  y="4"
                  fill="currentColor"
                  opacity="0.4"
                >
                  <animate
                    fill="freeze"
                    attributeName="y"
                    begin="svgSpinnersBarsScaleFade0.begin+0.15s"
                    dur="0.75s"
                    values="1;5"
                  />
                  <animate
                    fill="freeze"
                    attributeName="height"
                    begin="svgSpinnersBarsScaleFade0.begin+0.15s"
                    dur="0.75s"
                    values="22;14"
                  />
                  <animate
                    fill="freeze"
                    attributeName="opacity"
                    begin="svgSpinnersBarsScaleFade0.begin+0.15s"
                    dur="0.75s"
                    values="1;0.2"
                  />
                </rect>
                <rect
                  width="6"
                  height="14"
                  x="17"
                  y="4"
                  fill="currentColor"
                  opacity="0.3"
                >
                  <animate
                    id="svgSpinnersBarsScaleFade1"
                    fill="freeze"
                    attributeName="y"
                    begin="svgSpinnersBarsScaleFade0.begin+0.3s"
                    dur="0.75s"
                    values="1;5"
                  />
                  <animate
                    fill="freeze"
                    attributeName="height"
                    begin="svgSpinnersBarsScaleFade0.begin+0.3s"
                    dur="0.75s"
                    values="22;14"
                  />
                  <animate
                    fill="freeze"
                    attributeName="opacity"
                    begin="svgSpinnersBarsScaleFade0.begin+0.3s"
                    dur="0.75s"
                    values="1;0.2"
                  />
                </rect>
              </svg>
              <h3 class="text-xl font-normal text-gray-500 mt-5 mb-6">
                Confirmez le code et lancez le téléchargement !
              </h3>
              <p className="p-2 text-red-300">{errorMsg}</p>
              <div class="relative bg-inherit p-4">
                <input
                  type="text"                 
                  name="code"
                  value={code}
                  onChange={(e) => {
                    setCode(e.target.value);
                  }}
                  class="peer bg-transparent h-10 w-72 rounded-lg text-gray-800 placeholder-transparent ring-2 px-2 ring-gray-500 focus:ring-sky-600 focus:outline-none focus:border-rose-600"
                  placeholder="Type inside me"
                />
                <label
                  for="username"
                  class="absolute cursor-text left-16 -top-0 text-sm text-gray-500 bg-white mx-1 px-1 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-500 peer-placeholder-shown:top-2 peer-focus:-top-0 peer-focus:text-sky-600 peer-focus:text-sm transition-all"
                >
                  Saisissez le code
                </label>
              </div>
              <button
                onClick={() => {
                  getCode();
                }}                
                class="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-base inline-flex items-center px-3 py-2.5 text-center mr-2"
              >
                Télécharger
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 W-6 ml-2 mt-1"
                  viewBox="0 0 24 24"
                >
                  <g
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-width="2"
                  >
                    <path
                      stroke-dasharray="2 4"
                      stroke-dashoffset="6"
                      d="M12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21"
                    >
                      <animate
                        attributeName="stroke-dashoffset"
                        dur="0.6s"
                        repeatCount="indefinite"
                        values="6;0"
                      />
                    </path>
                    <path
                      stroke-dasharray="30"
                      stroke-dashoffset="30"
                      d="M12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3"
                    >
                      <animate
                        fill="freeze"
                        attributeName="stroke-dashoffset"
                        begin="0.1s"
                        dur="0.3s"
                        values="30;0"
                      />
                    </path>
                    <path
                      stroke-dasharray="10"
                      stroke-dashoffset="10"
                      d="M12 8v7.5"
                    >
                      <animate
                        fill="freeze"
                        attributeName="stroke-dashoffset"
                        begin="0.5s"
                        dur="0.2s"
                        values="10;0"
                      />
                    </path>
                    <path
                      stroke-dasharray="6"
                      stroke-dashoffset="6"
                      d="M12 15.5l3.5 -3.5M12 15.5l-3.5 -3.5"
                    >
                      <animate
                        fill="freeze"
                        attributeName="stroke-dashoffset"
                        begin="0.7s"
                        dur="0.2s"
                        values="6;0"
                      />
                    </path>
                  </g>
                </svg>
              </button>
              <button
                onClick={() => setShowConfirm(false)}
                class="text-gray-900 bg-white hover:bg-gray-100 focus:ring-4 focus:ring-cyan-200 border border-gray-200 font-medium inline-flex items-center rounded-lg text-base px-3 py-2.5 text-center"
                data-modal-toggle="delete-user-modal"
              >
                Abandonner
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}
export default Confirmation;
